import Vue from 'vue'
import Router from 'vue-router'

import {loanRoutes} from '../views/loans/routes';
import {authRoutes} from '../views/auth/routes';
import {taskRoutes} from '../views/taskManager/routes';
import {usersRoutes} from '../views/users/routes';
import {profileRoutes} from '../views/profile/routes';
import {assetsRoutes} from '../views/assets/routes';
import {borrowersRoutes} from '../views/borrowers/routes';
import {lendersRoutes} from '../views/lenders/routes';
import {bankAccountRoutes} from '../views/bankAccounts/routes';
import {marketDataRoutes} from '../views/marketData/routes';
import {staticDataRoutes} from '../views/staticData/routes';
import {documentsRoutes} from '../views/documents/routes';
import {reportsRoutes} from '../views/reports/routes';
import {notFoundRoutes} from '../views/NotFound/routes';

Vue.use(Router)
let routes = [

];

routes = [ ...loanRoutes, ...authRoutes, ...taskRoutes,...usersRoutes,...profileRoutes,...assetsRoutes,...borrowersRoutes,...lendersRoutes,...bankAccountRoutes,...marketDataRoutes,...staticDataRoutes,...documentsRoutes,...reportsRoutes,...notFoundRoutes,];
const router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes, // short for `routes: routes`
    })

router.beforeEach((to, from, next) => {  
    window.route = {
        to: to,
        from: from,
        next: next
    }
    if(to.matched.some(record => record.meta.requiresAuth)){
        let loggedin = window.getStorage('user-token')
        
        if (loggedin !== null){  // I AM LOGGED IN HERE
            if(!to.matched.some(record => record.meta.allowForcePass)){
                let permissionData = window.getStorage("aclData");
                permissionData = JSON.parse(permissionData)
                let resourceArr = to.meta.resource
                var perData = permissionData.filter((val) => to.meta.resource.includes(val.resource) && to.meta.permission.includes(val.permission))

                if(perData.length) {
                    next() 
                }else {
                    next({name: 'dashboard', replace: true})
                }            
                
                let passwordCheck = window.getStorage('user-profile')
                let forcepass = JSON.parse(passwordCheck)
                forcepass = forcepass.forcePasswordReset
                next(forcepass == true ? {name: 'UpdatePassword', replace: true} : true)        
            } else {
                next() 
            }         
        } else {
            next({name: 'login', replace: true, query: {return: to.fullPath}})
        }
    } else {
        next()
    }
})

export default router
<template>
  <footer class="main-footer" style="position:absolute;bottom:0">
    <div class="row col-12" style="margin-left: 200px">
      <div class="col-3"></div>
      <div class="col-3"></div>
      <div class="col-3"></div>
      <div class="col-3" style="padding-right: 200px">
        <p>&copy; 2024 Mount Street Group</p>
        <p>v1.8.3</p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "TheFooter",
};
</script>
<style lang="scss" scoped>
.main-footer {
  // padding: 0.5rem 1.5rem 1rem;
  p {
    text-align: right;
    font-size: 8px;
    font-weight: bold;
    color: #808080;
    margin-bottom: 0;
  }
}
</style>

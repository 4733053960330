<template>
  <button   class="btn-with-loading btn" @click.prevent="btnClicked" :disabled="loading" :class="{'overlay-loading' : overlayLoading, 'loading': loading}">
        <div class="btn-text">
            <slot>button</slot>
        </div>
        <template v-if="loading">
            <slot name="icon">
              <template v-if="iconType === 'iconDefault'">
                  <div class="loading-icon" :style="{right: btnStyles.right + 'px', top: btnStyles.top + '%'}">
                      <span class=" spinner-border spinner-border-sm"></span>
                  </div>
              </template>
              <template v-else-if="iconType === 'iconSpinner'">
                  
                <div class="lds-spinner loading-icon" :style="{right: btnStyles.right + 'px', top: btnStyles.top + '%'}">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </template>
              <template v-else-if="iconType === 'iconGrow'">
                  <div class="loading-icon" :style="{right: btnStyles.right + 'px', top:btnStyles.top + '%'}">
                      <span class="spinner-grow spinner-grow-sm "></span>
                  </div>
              </template>
            </slot>
        </template>
  </button>
</template>

<script>
export default {
  name: "LoadingButton",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: "iconDefault"
    },
    overlayLoading: {
        type: Boolean,
        default: true
    },
    btnStyles: {
      type:Object,
       default: () => {
         return{
           top:50,
           right: 8
         }         
       }
    }
  },
  data() {
    return {
      showActionButton: false
    };
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    btnClicked() {
      this.$emit("btnClicked");
    },
    checkPermission() {
            let resource = this.$route.meta.resource;
            // console.log("resource", resource)
            var permissionData = window.getStorage("aclData");
            permissionData = JSON.parse(permissionData);
            if(permissionData){
              permissionData.filter((item) => {
                  // if(item.resource == this.resource && item.permission == this.permission) {
                  if(item.resource == resource && item.permission == this.permission) {
                      this.showActionButton = true;
                  }
              })
            }
        }
  }
};
</script>
<style lang="scss">
@import '@/assets/scss/components/_btnLoading.scss';
</style>
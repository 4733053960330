<template>
  <div>
    <header id="main-header">
      <div class="navbar-header">
        <ul class="top-menu nav">
          <li class="nav-item MS-logo">
            <a href="#">
              <img src="@/assets/images/logo.png" alt="CreditHub Logo" />
            </a>
          </li>
          <li class="nav-item dropdown" v-click-outside="clickOutside">
            <a class="dropdown-toggle navbar-account-btn" @click="dropdown = !dropdown">
              <div class="nav-profile__img">
                <!-- <img src="@/assets/images/man.jpeg" alt=""> -->
                <!-- <img :src="profileImage" :alt="userName + ' photo'"> -->
                <span class="nav-profile__text">{{ userName.charAt(0) }}</span>
              </div>

              <span class="client">{{ userName }}</span>
            </a>
            <transition name="fade">
              <ul class="dropdown" v-if="dropdown">
                <li>
                  <a href="" @click.prevent="viewProfile">
                    <i class="material-icons">person</i>
                    My Profile
                  </a>
                </li>
                <li>
                  <a href="" @click.prevent="viewChangePassword">
                    <i class="material-icons">vpn_key</i>
                    Change Password
                  </a>
                </li>
                <li>
                  <a href="" @click.prevent="logout">
                    <i class="material-icons">power_settings_new</i>
                    Logout
                  </a>
                </li>
              </ul>
            </transition>
          </li>
          <li
            class="nav-item dropdown notifications"
            v-click-outside="clickOutsideNotification"
          >
            <a
              id="notification-display"
              @click="showNotificationBox = !showNotificationBox"
            >
              <i class="material-icons"> notifications_none </i>
              <span v-if="notificationList.length > 0">
                {{ notificationList.length }}
              </span>
            </a>
          </li>
        </ul>

        <NotificationList
          v-if="showNotificationBox"
          :notificationList="notificationList"
          @notificationItemClicked="notificationClicked"
          @clearAllNotifications="clearAllNotifications"
          @clearNotification="clearNotification"
        >
        </NotificationList>
      </div>
    </header>
    <SessionAlert
      :showSessionAlert="showSessionAlertBox"
      @showSessionAlertPopup="showSessionAlertPopup"
    ></SessionAlert>
  </div>
</template>

<script>
import { ClickOutside } from "@/directives/clickoutside";
import UserManager from "@/library/users/manager";
import SessionAlert from "@/components/SessionAlert.vue";
import TaskManager from "@/library/tasks/manager";
import * as signalR from "@microsoft/signalr";
import NotificationList from "@/layout/TheNotificationList.vue";

export default {
  name: "TheHeader",
  data() {
    return {
      profileImage: require("@/assets/images/man.jpeg"),
      dropdown: false,
      userName: "",
      showSessionAlertBox: false,
      showNotificationBox: false,
      notificationList: [],
    };
  },
  components: {
    SessionAlert,
    NotificationList,
  },
  directives: {
    ClickOutside,
  },
  beforeMount() {
    this.getUserProfileData();
    this.startSignalRService();
  },
  mounted() {
    this.getNotifications();
    this.inactivityTime();
  },
  methods: {
    startSignalRService() {
      //console.log('startSignalRService')
      const bearerToken = window.getStorage("user-token");
      // console.log('bearerToken', bearerToken)
      let notifications = [];
      let connectionId;
      let hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(window.config.notification.hub_url, {
          accessTokenFactory: () => {
            return bearerToken;
          },
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      hubConnection.on("notify", (data) => {
        //console.log('signalR data received: ', data);
        if (Array.isArray(data)) {
          // console.log('signalR data is array');
          this.notificationList = data.sort((a, b) => new Date(b.updatedTimestamp).getTime() - new Date(a.updatedTimestamp).getTime());
        } else {
          //console.log('signalR data is not array.');
          //this.notificationList.push(data);
          let notificationIds = this.notificationList.map((item) => (item = item.id));
          //console.log('notificationIds', notificationIds);
          //console.log('notificationList', this.notificationList);
          if (!notificationIds.includes(data.id)) {
            this.notificationList.unshift(data);
          }
        }
      });

      hubConnection
        .start()
        // .then(() => console.log('Connection Started'))
        .then()
        .catch((err) => console.log("Error while starting connection: " + err));

      connectionId = hubConnection.connectionId;
    },
    getNotifications() {
      //console.log('getNotifications')
      TaskManager.getNotifications((response, error) => {
        if (response) {
          //console.log('response', response)
          this.notificationList = response.result.sort((a, b) => new Date(b.updatedTimestamp).getTime() - new Date(a.updatedTimestamp).getTime());
          // this.notificationList.reverse();
          //console.log('this.notificationList', this.notificationList);
          //this.$store.commit('notifications/setNotifications', this.notificationList);
          //console.log('notifications in store: ', this.$store.getters['notifications/getNotifications']);
        }
        if (error) {
          this.dispacthNotifications();
          // console.log("api call error")
        }
      });
    },
    dispacthNotifications() {
      TaskManager.dispacthNotifications((response, error) => {
        if (response) {
          // console.log('response', response)
        }
      });
    },
    notificationClicked(id) {
      // console.log('notificationClicked', id)
      let clickedNotification = this.notificationList.filter(
        (notification) => notification.id === id
      );
      //console.log('clickedNotification', clickedNotification);

      let idList = [id];
      //console.log('idList', idList)
      TaskManager.clearNotifications(idList, (response, error) => {
        // console.log('clearNotifications', response)
        this.getNotifications();
      });

      let targetContent = {
        id: clickedNotification[0].taskId,
        target: "body",
        accessFromWorkflow: clickedNotification[0].isWorkflowTask,
        workflowName: clickedNotification[0].workflowName,
        notificationToClear: clickedNotification[0].id,
      };
      this.$router.push({
        name: "TaskManager.TaskViewerTaskPanel",
        params: {
          slug: `${clickedNotification[0].taskId}`,
          data: targetContent,
        },
      });
    },
    clearAllNotifications() {
      let idList = this.notificationList.map(
        (notification) => (notification = notification.id)
      );
      //console.log('in clear all notifications. IDs: ', idList);
      TaskManager.clearNotifications(idList, (response, error) => {
        this.getNotifications();
      });
    },
    clearNotification(id) {
      let clickedNotification = this.notificationList.filter(
        (notification) => notification.id === id
      );
      console.log("notification to clear: ", clickedNotification);
      TaskManager.clearNotifications([clickedNotification[0].id], (response, error) => {
        console.log("clearNotifications", response);
        this.getNotifications();
      });
    },
    showSessionAlertPopup() {
      this.showSessionAlertBox = false;
    },
    inactivityTime() {
      var time;
      var self = this;
      window.onload = resetTimer;
      // DOM Events
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer; // touchscreen presses
      document.onkeypress = resetTimer;
      document.onload = resetTimer;
      document.ontouchstart = resetTimer; // touchpad clicks
      document.onclick = resetTimer;
      document.addEventListener("scroll", resetTimer, true);

      function logout() {
        // alert("You are now logged out.");
        if (self.$route.name != "login") {
          self.showSessionAlertBox = true;
          // localStorage.clear();
          sessionStorage.clear();
        }
      }

      function resetTimer() {
        clearTimeout(time);
        time = setTimeout(logout, 1800000);
        // time = setTimeout(logout, 5000)
        // 1000 milliseconds = 1 second
      }
    },
    clickOutside() {
      this.dropdown = false;
    },
    clickOutsideNotification() {
      this.showNotificationBox = false;
    },
    viewProfile() {
      this.dropdown = false;
      if (this.$route.name !== "Profile") {
        this.$router.push("/profile").then(() => window.scrollTo(0, 0));
      }
    },
    viewChangePassword() {
      let targetContent = "changePassword";
      if (this.$route.name !== "Profile") {
        this.$router.replace({ name: "Profile", params: { targetContent } });
      }
      this.dropdown = false;
    },
    getUserProfileData() {
      if (window.getStorage("user-token") && window.getStorage("user-token") != "null") {
        var profileData = window.getStorage("user-profile");
        var profileDataJson = JSON.parse(profileData);

        this.userName = profileDataJson["firstName"] + " " + profileDataJson["lastName"];
      }
    },
    logout() {
      // if (window.getStorage("ColumnVisibleList") != null) {
      //     window.removeStorage('ColumnVisibleList')
      // }

      // if (window.getStorage("DealGridData") != null) {
      //     window.removeStorage('DealGridData')
      // }
      // if (window.getStorage("user-token") != null) {
      //     window.removeStorage('user-token')
      // }

      // if (window.getStorage("user-profile") != null) {
      //     window.removeStorage('user-profile')
      // }

      // if (window.getStorage("active-tab") != null) {
      //     window.removeStorage("active-tab")
      // }

      // if (window.getStorage("themeColor") != null) {
      //     window.removeStorage("themeColor")
      // }

      // if (window.getStorage("loginStatus") != null) {
      //     window.removeStorage("loginStatus")
      // }

      sessionStorage.clear();
      // localStorage.clear();
      this.$router.replace({ name: "login" });
      // this.$emit('logout')
      this.$router.go(0);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout/_header.scss";

.dropdown-notification-menu .dropdown-header {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.25rem;
  h6 {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 0;
  }
  a:hover,
  a:focus {
    color: darken(#d40000, 15%) !important;
  }
}
.dropdown-notification-menu .dropdown-footer {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.25rem;

  h6 {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 0;
  }

  a:hover,
  a:focus {
    color: darken(#d40000, 15%) !important;
  }
}
.dropdown-notification-menu {
  position: absolute;
  top: 100%;
  right: 24px;
  z-index: 1000;
  min-width: 10rem;
  // padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  width: 320px;
  max-height: 375px;
  overflow-y: auto;
}
.dropdown-notification-menu .dropdown-list {
  // padding-left: 20px;
  // padding-right: 20px;
  padding: 0.5rem 0 1rem;
  margin-bottom: 0;
  li {
    // background-color: #eee;
    padding: 0.75rem;
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: #eee;
    }
  }
  li.empty-notification {
    &:hover,
    &:focus {
      cursor: auto;
      background-color: #fff;
    }
    i:hover,
    i:focus {
      cursor: auto;
    }
  }
  .mail-content {
    // width: 300px;
    font-size: smaller;
  }
  .no-notification {
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #333;
    text-align: center;
  }
}
.dropdown-notification-menu .dropdown-list .btn-wrapper {
  width: 20px;
  float: left;
  margin-right: 10px;
}
.dropdown-notification-menu .dropdown-list li {
  list-style: none;
}
.dropdown-notification-menu .dropdown-list > li .btn-wrapper .btn-circle {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  // background-color: #ce181e;
  background-color: #666;
  color: #fff;
  border-color: unset;
}
#notification-display span {
  background: #d40000;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 10px;
  font-weight: bolder;
  // padding: 2px;
  height: 18px;
  width: 18px;
  // text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#notification-display i {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}
</style>

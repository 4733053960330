export const authRoutes = [
    {
        path: '/',
        redirect: {
            name: "login"
        }
    },
    
    {
        path: "/user/login",
        name: "login",
        component: () => import('./Login')
    },
    {
        path: "/user/resetPassword",
        name: "loginReset",
        component: () => import('./LoginReset')
    },
    {
        path: "/user/register",
        name: "UserRegister",
        component: () => import('./SignUp')
    },{
        path: "/user/updatePassword",
        name: "UpdatePassword",
        component: () => import('./UpdatePassword')
    },
]
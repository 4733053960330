<template>
  <transition name="fadeIn">
    <div
      class="overlay-modal-disable sessionalert"
      v-if="showSessionAlert"
      :class="theme"
    >
      <div class="bg-image"></div>
      <div class="overlay-content">
        <transition name="zoom">
          <div class="dialog-box session-box session-alert-box">
            <div class="text-right">
              <a
                href="#"
                class="close-icon sessionBoxCloseIcon btn btn-secondary close-overlay"
                @click="closeSessionAlertBox"
              >
                <i class="material-icons">close</i>
              </a>
            </div>

            <slot name="header">
              <p v-html="headingText" class="session-box__text"></p
            ></slot>
            <!-- Login starts -->
            <div class="form-content">
              <div
                class="login-error-message alert alert-danger password-changed-message"
                v-if="displayPasswordChanged"
              >
                <h3 class="password-reset">
                  Your password has been changed. Please login.
                </h3>
              </div>
              <div
                class="login-error-message alert alert-danger"
                v-if="!showErrorPassword && !showErrorEmail && showError"
              >
                <span class="material-icons mr-2">error_outline</span>
                {{ errorMessage }}
              </div>
              <!-- profile -->
              <div class="username">
                <div class="nav-profile__img">
                  <span class="nav-profile__text">{{ userFullName.charAt(0) }}</span>
                </div>
                <h5 class="client">{{ userFullName }}</h5>
              </div>
              <!-- profile ends -->
              <ValidationObserver
                ref="observer"
                v-slot="{}"
                tag="form"
                @submit.prevent="userLogin()"
              >
                <form class="form-modal mt-4 pb-4" method="post">
                  <!-- <ValidationProvider name="input.username" rules="required" data-vv-as="email" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label for="uname">Username</label>
                                            <input type="text" class="form-control form-control-lg" v-model="input.username" placeholder="">
                                            <span class="login-error-message" v-if="showErrorEmail && showError">{{errorMessage}}</span>
                                        </div>
                                        <p class="error login-page" v-if="errors.length">{{ errors[0] }}</p>
                                    </ValidationProvider> -->
                  <ValidationProvider
                    name="input.password"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div class="form-group">
                      <span class="input-modal flex-input">
                        <label class="password" for="Password">Password</label>
                      </span>
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        v-model="input.password"
                        placeholder=""
                      />
                      <span
                        class="login-error-message"
                        v-if="showErrorPassword && showError"
                        >{{ errorMessage }}</span
                      >
                    </div>
                    <p class="error login-page" v-if="errors.length">{{ errors[0] }}</p>
                  </ValidationProvider>
                  <div class="input-btn mt-4">
                    <BtnLoading
                      class="btn-primary"
                      :btnStyles="styles"
                      @btnClicked="userLogin()"
                      :loading="loading"
                      >Sign in</BtnLoading
                    >
                    <a href="#" @click="closeSessionAlertBox" class="op-75">Not me</a>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <!-- login ends -->
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import UserValidation from "../validation/user/";
import UserManager from "@/library/users/manager";
// import ProfileVue from '../profile/Profile.vue'
import BtnLoading from "@/components/ui/BtnLoading";
import TaskManager from "@/library/tasks/manager";
import DealManager from "@/library/deals/manager";

export default {
  props: {
    headingText: {
      type: String,
      default: "Your session has expired. Please log in.",
    },
    showSessionAlert: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "theme2",
    },
  },
  components: {
    BtnLoading,
  },
  data() {
    return {
      // showContent: false
      errorMessage: "",
      showErrorEmail: false,
      showErrorPassword: false,
      showError: false,
      input: {
        username: "",
        password: "",
      },
      styles: {
        top: 50,
        // right:35
      },
      loading: false,
      showme: true,
      displayPasswordChanged: false,
      currentRoute: "",
      loggedInUserId: "",
      userFullName: "",
    };
  },
  created() {
    this.checkIfFromForcePassword();
    this.checkUserToken();
    this.$emit("login", true);
    this.$emit("userLogin", true);
  },
  mounted() {
    var profileData = window.getStorage("user-profile");
    var profileDataJson = JSON.parse(profileData);
    this.loggedInUserId = profileDataJson.id;
    this.userFullName = `${profileDataJson.firstName} ${profileDataJson.lastName}`;
    this.input.username = profileDataJson.email;
  },
  methods: {
    closeSessionAlertBox() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$router.replace({ name: "login" });
      location.reload();
    },
    checkIfFromForcePassword() {
      if (Object.keys(this.$route.params).length > 0) {
        if (this.$route.params.data) {
          if (this.$route.params.data.target) {
            if (this.$route.params.data.target == "forcePassword") {
              this.displayPasswordChanged = true;
            }
          }
        }
      }
    },
    checkUserToken() {
      let userToken = window.getStorage("user-token");
      if (userToken != null) {
        this.showme = false;
        // this.$router.push("/dashboard").then(() => window.scrollTo(0, 0))
        // this.$router.push(this.$route.path).then(() => window.scrollTo(0, 0))
      }
    },
    login() {
      if (this.input.username == "" || this.input.password == "") {
        this.showError = false;
        return;
      } else {
        this.showError = false;
      }
      // this.$router.push("/dashboard").then(() => window.scrollTo(0, 0))
      // this.$router.push(this.$route.path).then(() => window.scrollTo(0, 0))
      this.$emit("setLogin", true);
    },
    resetUserPassword() {
      this.$router.push("/user/resetPassword").then(() => window.scrollTo(0, 0));
    },
    registerNewUser() {
      this.$router.push("/user/register").then(() => window.scrollTo(0, 0));
    },
    async setStatusStorage() {
      let allStatus = [];
      let taskviewerClosedStatus = [];

      const taskviewerStatus = await this.getTaskviewerStatus();
      const myTasksStatus = await this.getMyTasksStatus();
      const myApprovalsStatus = await this.getMyApprovalsStatus();
      const vendors = await this.getVendors();
      const referenceTypes = await this.getReferenceTypes();
      const taskTypes = await this.getTaskTypesData();
      const docvaultAuth = await this.authenticatDocvaulteUser();

      taskviewerStatus.forEach((item) => {
        if (!allStatus.includes(item.name)) {
          allStatus.push(item.name);
        }

        if (item.isClosed == true) {
          if (!taskviewerClosedStatus.includes(item.name)) {
            taskviewerClosedStatus.push(item.name);
          }
        }
      });

      this.allTasksStatus = {
        allStatus: allStatus,
        taskviewerClosedStatus: taskviewerClosedStatus,
        myTaskStatus: myTasksStatus,
        myApprovalsStatus: myApprovalsStatus,
      };

      // localStorage.clear();
      window.setLocalStorage("status", JSON.stringify(this.allTasksStatus));
      window.setLocalStorage("vendors", JSON.stringify(vendors));
      window.setLocalStorage("reference_types", JSON.stringify(referenceTypes));
      window.setLocalStorage("task_types", JSON.stringify(taskTypes));
    },
    authenticatDocvaulteUser() {
      return new Promise((resolve, reject) => {
        TaskManager.docvaultAuthenticateUser((response, error) => {
          if (response) {
            window.setStorage("docvault_user_token", response.result.data.access_token);
            if (
              window.getStorage("docvault_user_token") &&
              window.getStorage("docvault_user_token") != null
            ) {
              window.docvault_user_token = window.getStorage("docvault_user_token");
              resolve(window.docvault_user_token);
            }
            this.expiredTime = response.result.data.expires_in;
            let self = this;
            setInterval(function () {
              if (self.expiredTime > 0 && self.expiredTime !== undefined) {
                self.expiredTime -= 1;
              }
            }, 1000);
          }
        });
      });
      // return new Promise((resolve, reject) => {
      //     TaskManager.authenticateUser((response, error) => {
      //         if(response) {
      //             window.setStorage('docvault_user_token', response.data.access_token);
      //             if(window.getStorage('docvault_user_token') && window.getStorage('docvault_user_token') != null) {
      //                 window.docvault_user_token = window.getStorage('docvault_user_token');
      //                 resolve(window.docvault_user_token);
      //             }

      //         this.expiredTime = response.data.expires_in;
      //         let self = this
      //         setInterval(function () {
      //             if (self.expiredTime > 0 && self.expiredTime !== undefined) {
      //                 self.expiredTime -= 1
      //             }
      //         }, 1000);
      //     }
      //     });
      // })
    },
    getTaskviewerStatus() {
      return new Promise((resolve, reject) => {
        TaskManager.getStatuses((response, error) => {
          if (response) {
            resolve(response.result);
          }
        });
      });
    },
    getMyTasksStatus() {
      return new Promise((resolve, reject) => {
        let taskGroup = "MyTasks";
        let myTasksStatus = [];
        TaskManager.getAllTaskStatuses(taskGroup, (response, error) => {
          if (response) {
            response.result.forEach((item) => {
              if (!myTasksStatus.includes(item.startingStatus.name)) {
                myTasksStatus.push(item.startingStatus.name);
              }
            });
            resolve(myTasksStatus);
          }
        });
      });
    },
    getMyApprovalsStatus() {
      return new Promise((resolve, reject) => {
        let taskGroup = "MyApprovals";
        let myApprovalsStatus = [];
        TaskManager.getAllTaskStatuses(taskGroup, (response, error) => {
          if (response) {
            response.result.forEach((item) => {
              if (!myApprovalsStatus.includes(item.startingStatus.name)) {
                myApprovalsStatus.push(item.startingStatus.name);
              }
            });
            resolve(myApprovalsStatus);
          }
        });
      });
    },
    getVendors() {
      return new Promise((resolve, reject) => {
        let vendorsList = [];
        DealManager.getVendors((response, error) => {
          if (response) {
            response.result.forEach((item) => {
              vendorsList.push(item);
            });
            resolve(vendorsList);
          }
        });
      });
    },
    getReferenceTypes() {
      return new Promise((resolve, reject) => {
        let referenceTypesList = [];
        TaskManager.getReferenceTypes((response, error) => {
          if (response) {
            response.result.forEach((item) => {
              if (item.isActive == true) {
                referenceTypesList.push(item);
              }
            });
            resolve(referenceTypesList);
          }
        });
      });
    },
    getTaskTypesData() {
      return new Promise((resolve, reject) => {
        let taskTypeDatas = [];
        TaskManager.getTaskTypes((response, error) => {
          var nameSentencase = "";
          var nameIdValue = "";
          if (response) {
            response.result.forEach((item, index) => {
              nameSentencase = item.name.replace(/([A-Z])/g, " $1").trim();
              nameIdValue = item.name.replace(/^.{1}/g, item.name[0].toLowerCase());
              taskTypeDatas.push({
                id: nameIdValue,
                text: nameSentencase,
                taskTypeId: item.id,
              });
            });
            resolve(taskTypeDatas);
          }
        });
      });
    },
    async userLogin() {
      const valid = await this.$refs.observer.validate();
      this.loading = true;
      UserManager.authenticate(this.input, (response, error) => {
        if (this.input.username != "" && this.input.password != "") {
          this.showError = true;
          this.showErrorEmail = false;
          this.showErrorPassword = false;
        }

        if (error) {
          this.loading = false;
          this.errorMessage = "User Authentication failed";
          return;
        }

        if (response && valid) {
          this.loading = false;
          // this.showSessionAlert = false;
          this.$emit("showSessionAlertPopup", false);
          this.showError = false;
          var profileData = window.getStorage("user-profile");
          var profileDataJson = JSON.parse(profileData);
          var userId = profileDataJson.id;
          this.errorMessage = "";
          UserManager.getUserPermissionAcl(userId, (response, error) => {
            // this.loading = false;
            if (response) {
              if (
                window.getStorage("user-token") &&
                window.getStorage("user-token") != null
              ) {
                this.setStatusStorage();
              }
              this.setGridPreference();
              //   this.$router.push("/dashboard").then(() => window.scrollTo(0, 0))
              // this.$router.push(this.$route.path).then(() => window.scrollTo(0, 0));
              // this.input.username = "";
              this.input.password = "";
              if (profileDataJson.forcePasswordReset && this.loggedInUserId != userId) {
                this.$router
                  .replace({ name: "UpdatePassword" })
                  .then(() => window.scrollTo(0, 0));
                // this.$router.push("/user/updatePassword").then(() => window.scrollTo(0, 0))
              } else if (
                this.loggedInUserId != userId &&
                !profileDataJson.forcePasswordReset
              ) {
                this.$router.push("/taskmanager/tasks").then(() => window.scrollTo(0, 0));
              }
            }
          });
        }
      });
    },
    setThemeColor(value) {
      let bodyTag = document.getElementsByTagName("BODY")[0];
      if (bodyTag.classList.length > 0) {
        bodyTag.classList.forEach((className) => {
          if (className == "themeOne") {
            bodyTag.classList.remove("themeOne");
          }
          if (className == "dark-origin") {
            bodyTag.classList.remove("dark-origin");
          }
          if (className == "dark-third") {
            bodyTag.classList.remove("dark-third");
          }
        });
      }
      bodyTag.classList.add(value);
    },
    setGridPreference() {
      var profileData = window.getStorage("user-profile");
      var profileDataJson = JSON.parse(profileData);
      var userId = profileDataJson.id;

      TaskManager.getPreferences(userId, false, false, (response, error) => {
        if (response != null) {
          // if (response.isError) {
          // if(response.isError == false) {
          var gridPreferences = response.result;
          if (gridPreferences) {
            for (let [key, value] of Object.entries(gridPreferences)) {
              if (key == "locale-setting") {
                window.setStorage(key, value);
              } else {
                window.setStorage(key, JSON.stringify(value));
              }
              if (key == "themeColor") {
                this.setThemeColor(value);
              }

              if (key == "timezone") {
                window.setStorage(key, value);
              }
              this.setLocaleSettings();
            }
            if (this.loggedInUserId != userId) {
              location.reload();
            } else {
              this.$emit("showSessionAlertPopup", true);
              this.input.password = "";
              // this.input.username = "";
              this.loading = false;
            }
          }
          // }

          // }
        }

        if (response.isError == true) {
          this.$emit("showSessionAlertPopup", true);
          this.input.password = "";
          // this.input.username = "";
          this.loading = false;
          this.setLocaleSettings();
        }
      });
    },
    setLocaleSettings() {
      if (window.getStorage("locale-setting") === null) {
        window.setStorage("locale-setting", "en-GB");
      }

      if (window.getStorage("timezone") === null) {
        window.setStorage("timezone", "Europe/London");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/components/_alert.scss";
@import "@/assets/scss/pages/_loginForm.scss";
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.3s;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

// session box
.session-box {
  // text-align: left;
  &__text {
    color: #d40000;
  }
  .form-control-lg {
    font-size: 1rem;
  }
  .login-error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
  }
  .input-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 0.5rem 2.5rem;
      border: none;
    }
  }

  .op-75 {
    opacity: 0.75;
  }
}

.username {
  .nav-profile__img {
    width: 48px;
    height: 48px;
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
  .nav-profile__img .nav-profile__text {
    font-size: 24px;
  }
}

// from login vue
p.error.login-page {
  color: red;
  margin-top: -12px;
  display: block;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
}
.alert-danger.password-changed-message {
  color: #28a745;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger.password-changed-message h3 {
  font-size: 14px;
  color: #155724;
}
.session-box.session-alert-box {
  min-width: 390px;
  position: relative;
  border-radius: 0.3rem !important;
}
a.close-icon.sessionBoxCloseIcon {
  top: 0 !important;
  right: 0 !important;
}
.bg-image {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  height: 100vh;
  width: 100%;
  position: absolute;
}
.overlay-modal-disable.sessionalert {
  z-index: 99999;
}
</style>
